/* eslint-disable */
import { ref, watch, computed } from "@vue/composition-api";
export default function usenoticesList() {
  const refnoticeListTable = ref(null);

  const tableColumns = [
    { key: "Data", sortable: false },
    { key: "Título", sortable: false },
    { key: "Geral", sortable: false },
  ];

  const perPage = ref(10);
  const totalnotices = ref(0);
  const currentPage = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refnoticeListTable.value
      ? refnoticeListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalnotices.value,
    };
  });

  return {
    tableColumns,
    perPage,
    currentPage,
    totalnotices,
    dataMeta,
    refnoticeListTable,
  };
}
