<template>
  <b-sidebar
    id="add-new-notice-sidebar"
    :visible="isAddNewNoticeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-elg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-notice-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("message.notice.createNewNotice") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewNotice())"
          @reset.prevent="resetForm"
        >
          <!-- Title -->
          <validation-provider #default="validationContext" rules="required">
            <b-form-group
              label-for="title"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.notice.title") }}
              </template>
              <b-form-input
                id="title"
                v-model="noticeData.title"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Date -->
          <validation-provider
            #default="validationContext"
            name="data"
            rules="required"
          >
            <b-form-group
              label-for="date"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.notice.date") }}
              </template>
              <flat-pickr
                v-model="noticeData.date"
                class="form-control"
                :config="flatConfig"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Route -->
          <validation-provider
            #default="validationContext"
            name="route"
            rules="required"
          >
            <b-form-group
              label-for="route"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.notice.routes") }}
              </template>
              <v-select
                multiple
                v-model="noticeData.route"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="routeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="route-select"
                @input="checkDescriptionOptions()"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <b-form-group label-for="description" v-if="email">
            <template v-slot:label>
              {{ $t("message.notice.description") }}
            </template>
            <div>
              <quill-editor
                class="editor-layout"
                :options="editorOption"
                v-model="noticeData.notice"
              />
            </div>
          </b-form-group>

          <!-- Description -->
          <b-form-group label-for="descriptionsms" v-if="sms">
            <template v-slot:label>
              {{ $t("message.notice.descriptionSms") }}
            </template>
            <div>
              <b-form-textarea
                name="textarea"
                rows="4"
                v-model="noticeData.smsNotice"
              />
            </div>
          </b-form-group>

          <!-- All Users -->
          <validation-provider
            #default="validationContext"
            name="todos usuarios"
            rules="required"
          >
            <b-form-group
              label-for="allusers"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.notice.allUsers") }}
              </template>
              <b-form-checkbox
                v-model="allUsersCheck"
                @change="selectUsers(allUsersCheck)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Users -->
          <validation-provider
            #default="validationContext"
            name="usuarios"
            rules="required"
            v-if="showMembersOptions"
          >
            <b-form-group
              label-for="users"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.notice.users") }}
              </template>
              <v-select
                multiple
                v-model="noticeData.users"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="users-role"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import Email from "@/@core/utils/email/email.js";
import date from "@/@core/utils/date";
import dateInput from "@/@core/utils/dateInput";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,
    quillEditor,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewNoticeSidebarActive",
    event: "update:is-add-new-notice-sidebar-active",
  },
  props: {
    isAddNewNoticeSidebarActive: {
      type: Boolean,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    routeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      showMembersOptions: false,
      allUsersCheck: true,

      email: false,
      sms: false,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      flatConfig: {
        dateFormat: "d/m/Y",
      },

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      disabledButton: false,
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  methods: {
    sendEmail(type, obj) {
      return Email(type, obj);
    },

    checkDescriptionOptions() {
      this.email = false;
      this.sms = false;

      if (this.noticeData.route != null || this.noticeData.route != undefined) {
        for (var i = 0; i < this.noticeData.route.length; i++) {
          if (this.noticeData.route[i] === "Email") {
            this.email = true;
          }
          if (this.noticeData.route[i] === "SMS") {
            this.sms = true;
          }
        }
      }
    },

    async sendSms(sms, userList) {
      await axios
        .get(`${URL_API}notice-sms/${sms}/${userList}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {});
    },

    selectUsers(value) {
      if (value === false) {
        this.showMembersOptions = true;
      } else {
        this.showMembersOptions = false;
      }
    },

    submitNewNotice() {
      this.disabledButton = true;
      this.onSubmit();

      this.noticeData.date = date(this.$i18n.locale, this.noticeData.date);

      this.noticeData.allUsers = this.allUsersCheck;

      if (this.noticeData.route != null || this.noticeData.route != undefined) {
        for (var i = 0; i < this.noticeData.route.length; i++) {
          if (this.noticeData.route[i] === "Email") {
            this.sendEmail("notice", this.noticeData);
          }
          if (this.noticeData.route[i] === "SMS") {
            this.sendSms(this.noticeData.smsNotice, this.noticeData.users);
          }
        }
      }

      axios({
        method: "post",
        url: `${URL_API}notice`,
        headers: getHeader(this.userData),
        data: this.noticeData,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.create"),
              icon: "CheckIcon",
              variant: "success",
              text: `Comunicado criado com sucesso`,
            },
          });
        })
        .then(() => {
          this.$root.$emit("newListNotice", null);
          this.disabledButton = false;
          this.sms = false;
          this.email = false;
        })
        .catch((error) => {
          this.disabledButton = false;
          console.log(error);
        });
    },
  },

  setup(props, { emit }) {
    const blanknoticeData = {};

    const noticeData = ref(JSON.parse(JSON.stringify(blanknoticeData)));
    const resetnoticeData = () => {
      noticeData.value = JSON.parse(JSON.stringify(blanknoticeData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-notice-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetnoticeData);

    return {
      noticeData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-notice-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.editor-layout {
  height: 300px;
  padding-bottom: 40px;
}
</style>
